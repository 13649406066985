.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.625rem;
  height: 100vh;
}
.notFound p {
  color: var(--clr-white);
  font-family: var(--ff-accent);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.backToMain {
  margin-top: 2.5rem;
  border-radius: var(--br-max);
  border: 0.125rem solid var(--clr-second);
  padding: 0.75rem 2.5rem;
  color: var(--clr-second);
  font-family: var(--ff-accent);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all var(--tt-main) ease-in-out;
}

.backToMain:hover {
  background-color: var(--clr-second);
  color: var(--clr-main);
}

@media (max-width: 64rem) {
  .notFound h1,
  .notFound h1 > span {
    font-size: 2.25rem;
  }
  .notFound p {
    font-size: 1rem;
  }
}
